import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const proApi = createApi({
  reducerPath: "proApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://xpro-api-33058f622ebb.herokuapp.com/api/v1",
  }),
  tagTypes: [
    "TRADES",
    "USERS",
    "SINGLE_TRADE",
    "SINGLE_USERS",
    "COINS",
    "SETTINGS",
    "ADMIN_USERS",
    "TICKETS",
    "SINGLE_TICKETS",
    "ADMIN_STATS",
  ],
  endpoints: (builder) => ({
    userLogin: builder.mutation({
      query: (body) => ({
        method: "POST",
        body,
        url: "/admin/signin/",
      }),
    }),

    getForgotPassword: builder.query({
      query: ({ email }) => ({
        method: "GET",
        url: "/auth/password-reset/send-otp?email=" + email,
      }),
    }),

    verifyOtp: builder.mutation({
      query: ({ email, otp }) => ({
        method: "POST",
        url: `/auth/verify-otp/${email}/${otp}`,
        body: { email, otp },
      }),
    }),

    passwordReset: builder.mutation({
      query: ({ body, token }) => ({
        method: "POST",
        body,
        url: "/auth/password-reset/new-password",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }),
    }),

    updateTradeStatus: builder.mutation({
      query: ({ id, status }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          body: {
            status: status,
          },
          url: `admin/trades/status/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["TRADES", "SINGLE_TRADE", "ADMIN_STATS"],
    }),

    deactivateUser: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          url: `admin/users/${id}/deactivate`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["SINGLE_USERS", "USERS"],
    }),

    activateUser: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          url: `admin/users/${id}/activate`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["SINGLE_USERS", "USERS"],
    }),

    getTrades: builder.query({
      query: ({
        tradeType,
        page,
        page_size,
        search_param,
        status,
        ...others
      }) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/trades`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            trade_type: tradeType,
            page: page + 1,
            page_size,
            search_param,
            status,
            ...others,
          },
        };
      },
      providesTags: ["TRADES"],
    }),

    getSingleTrade: builder.query({
      query: (id) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/trades/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["SINGLE_TRADE"],
    }),

    getAdminStats: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/trade-statistics`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["ADMIN_STATS"],
    }),

    getAllTickets: builder.query({
      query: ({ page, page_size, search_param }) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/ticket`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: page + 1,
            page_size,
            search_param,
          },
        };
      },
      providesTags: ["TICKETS"],
    }),

    getUsers: builder.query({
      query: ({ page, page_size, search_param, ...others }) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/users`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: page + 1,
            page_size,
            search_param,
            ...others,
          },
        };
      },
      providesTags: ["USERS"],
    }),

    getSingleUser: builder.query({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/users/${id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["SINGLE_USERS"],
    }),

    getTicketToken: builder.query({
      query: (id) => {
        const token = localStorage.getItem("token");
        return {
          url: `/ticket/${id}/weavy/access-token`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),

    getSingleTicket: builder.query({
      query: (id) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/ticket/${id.id}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["SINGLE_TICKETS"],
    }),

    getAdminUsers: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["ADMIN_USERS"],
    }),

    getUsersProfile: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/users/profile`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),

    scheduleMessage: builder.mutation({
      query: (message) => {
        const token = localStorage.getItem("token");
        return {
          method: "POST",
          body: message,
          url: `admin/messages/schedule`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["TRADES", "SINGLE_TRADE"],
    }),

    addNewCoin: builder.mutation({
      query: (coin) => {
        const token = localStorage.getItem("token");
        return {
          method: "POST",
          body: coin,
          url: `admin/coin`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["COINS"],
    }),

    getAdminCoins: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/coin`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["COINS"],
    }),

    deleteCoins: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "DELETE",
          url: `admin/coin/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["COINS"],
    }),

    activateAdminUser: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          url: `admin/users/${id}/activate`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["ADMIN_USERS"],
    }),

    deactivateAdminUser: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          url: `admin/users/${id}/deactivate`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["ADMIN_USERS"],
    }),

    getBanks: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/users/banks`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),

    getAdminMessages: builder.query({
      query: ({ page, page_size }) => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/messages`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: {
            page: page + 1,
            page_size,
          },
        };
      },
    }),

    getSettings: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/settings`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      providesTags: ["SETTINGS"],
    }),

    updateSettings: builder.mutation({
      query: ({ data }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          body: {
            item: data,
          },
          url: `admin/settings`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["SETTINGS", "COINS"],
    }),

    inviteAdminUser: builder.mutation({
      query: ({ data }) => {
        const token = localStorage.getItem("token");
        return {
          method: "POST",
          body: {
            ...data,
          },
          url: `admin/invite-user`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["ADMIN_USERS"],
    }),

    closeTicket: builder.mutation({
      query: ({ id }) => {
        const token = localStorage.getItem("token");
        return {
          method: "PUT",
          body: {
            status: "closed",
          },
          url: `admin/ticket/${id}/status`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
      invalidatesTags: ["SINGLE_TICKETS", "TICKETS"],
    }),

    getAdminUtilityStats: builder.query({
      query: () => {
        const token = localStorage.getItem("token");
        return {
          url: `/admin/utility/statistics`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const {
  useGetTradesQuery,
  useGetSingleTradeQuery,
  useUpdateTradeStatusMutation,

  useUserLoginMutation,

  useGetUsersQuery,
  useGetSingleUserQuery,

  useGetAdminUsersQuery,
  useGetAdminStatsQuery,

  useScheduleMessageMutation,

  useGetAllTicketsQuery,
  useGetTicketTokenQuery,
  useLazyGetTicketTokenQuery,

  useGetSingleTicketQuery,
  useLazyGetSingleTicketQuery,

  useGetUsersProfileQuery,

  useDeactivateUserMutation,
  useActivateUserMutation,

  useAddNewCoinMutation,
  useGetAdminCoinsQuery,

  useDeactivateAdminUserMutation,

  useActivateAdminUserMutation,

  useGetBanksQuery,

  useGetAdminMessagesQuery,

  useGetSettingsQuery,

  useUpdateSettingsMutation,

  useInviteAdminUserMutation,

  useCloseTicketMutation,

  useDeleteCoinsMutation,

  useGetAdminUtilityStatsQuery,

  useLazyGetForgotPasswordQuery,

  usePasswordResetMutation,

  useVerifyOtpMutation,
} = proApi;
